<template>
  <div class="div-input">
    <div :class="['input-custom', { error: isValidate }]">
      <label>
        {{ textFloat }}
        <span v-if="isRequired" class="text-danger">*</span>
      </label>
      <div>
        <b-button class="btn-category" @click="showModal = true"
          >Select Subcategory</b-button
        >
        <label class="pl-3" v-if="name === 'category'"
          >Select {{ CategorySelectList.length }} List
          <font-awesome-icon
            icon="times-circle"
            color="#979797"
            v-if="CategorySelectList.length !== 0"
            class="icon-delete pointer"
            @click="clearListcategory()"
          />
        </label>
        <label class="pl-3" v-else
          >{{ newnameselect }}
          <font-awesome-icon
            icon="times-circle"
            color="#979797"
            v-if="newnameselect"
            class="icon-delete pointer"
            @click="clearNamecategory()"
          />
        </label>
        <b-modal
          v-model="showModal"
          size="lg"
          title="Select Relate category"
          header-bg-variant="red"
          hide-footer
          centered
        >
          <b-row class="no-gutters mb-3">
            <b-col md="12" lg="6" class="justify-content-start">
              <b-input-group>
                <b-form-input
                  type="search"
                  id="header-search-bar"
                  class="search-bar"
                  @keyup.enter="handleSearch"
                  placeholder="Search Name"
                  v-model="filter.search"
                >
                </b-form-input>
                <b-input-group-append is-text class="mr-2">
                  <b-iconstack
                    font-scale="2"
                    type="submit"
                    @click="handleSearch"
                  >
                    <b-icon
                      stacked
                      icon="search"
                      scale="0.5"
                      variant="grey"
                    ></b-icon>
                  </b-iconstack>
                </b-input-group-append>
              </b-input-group>
            </b-col>
            <b-col
              lg="6"
              md="12"
              class="pl-2 pl-lg-0 mt-2 text-left text-lg-right"
            >
              <b-form-checkbox
                size="lg"
                v-model="showOnly"
                @change="setshowOnly()"
                >Show only selected category</b-form-checkbox
              >
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <b-table
                responsive
                striped
                hover
                :fields="fields"
                :items="CategoryList"
                :busy="isBusy"
                show-empty
                empty-text="No matching records found"
              >
                <template v-slot:cell(id)="data">
                  <b-form-checkbox
                    size="lg"
                    class="ml-3"
                    :value="data.item.id"
                    v-if="name === 'category'"
                    v-model="CategorySelectList"
                    @change="
                      setcategoryList(data.item.id, name, data.item.name)
                    "
                  ></b-form-checkbox>
                  <b-form-group v-else>
                    <b-form-radio
                      v-model="RelatecategorySelectList"
                      :value="data.item.id"
                      @change="
                        setcategoryList(data.item.id, name, data.item.name)
                      "
                    >
                    </b-form-radio>
                  </b-form-group>
                </template>
                <template v-slot:cell(image_url)="data">
                  <div
                    class="position-relative box-image image-contain"
                    v-bind:style="{
                      'background-image': 'url(' + data.item.image_url + ')',
                    }"
                  ></div>
                </template>
                <template v-slot:table-busy>
                  <div class="text-center text-black my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-2">Loading...</strong>
                  </div>
                </template>
              </b-table>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="6"
              lg="6"
              class="form-inline justify-content-center justify-content-lg-start"
            >
              <p class="mb-0 p-gray">
                Showing {{ showing }} to {{ showingTo }} of {{ rows }} entires
              </p>
            </b-col>
            <b-col
              cols="12"
              md="12"
              xl="6"
              lg="6"
              class="form-inline justify-content-center justify-content-lg-end mt-2 mt-lg-0"
            >
              <b-pagination
                v-model="filter.page"
                :total-rows="rows"
                :per-page="filter.take"
                first-text="First"
                prev-text="Prev"
                next-text="Next"
                last-text="Last"
                class="m-md-0"
                @change="pagination"
              ></b-pagination>
            </b-col>
          </b-row>
        </b-modal>
      </div>
      <div v-if="v && v.$error">
        <span class="text-error" v-if="v.required == false"
          >Please select.</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
export default {
  props: {
    CategoryList: {
      required: true,
      type: Object | Array,
    },
    textFloat: {
      required: true,
      type: String,
    },
    isRequired: {
      required: false,
      type: Boolean,
    },
    isValidate: {
      required: false,
      type: Boolean,
    },
    v: {
      required: false,
      type: Object,
    },
    categoryidList: {
      required: false,
      type: Array,
    },
    relatecategoryidList: {
      required: false,
      type: Number,
    },
    isBusy: {
      required: false,
      type: Boolean,
    },
    changeList: {
      required: false,
      type: Boolean,
    },
    name: {
      required: false,
      type: String,
    },
    rows: {
      required: false,
      type: Number,
    },
    showingTo: {
      required: false,
      type: Number,
    },
    showing: {
      required: false,
      type: Number,
    },
    nameselect: {
      required: false,
      type: String,
    },
    detailtext: {
      required: false,
      type: String,
    },
  },
  data() {
    return {
      showModal: false,
      isCategory: true,
      showOnly: false,
      filter: {
        search: "",
        page: 1,
        take: 5,
      },
      newnameselect: this.nameselect,
      CategorySelectList: this.categoryidList,
      newcategoryidList: this.categoryidList,
      RelatecategorySelectList: this.relatecategoryidList,
      newchangeList: this.changeList,
      fields: [
        {
          key: "id",
          label: "#",
        },
        {
          key: "image_url",
          label: "Thumbnail",
        },
        {
          key: "name",
          label: "Product Name",
          tdClass: "text-center",
        },
      ],
    };
  },
  watch: {
    showModal(val) {
      if (val === false) {
        this.showOnly = false;
        this.filter.page = 1;
        this.$emit(
          "setShowonly",
          this.showOnly,
          this.name,
          this.CategorySelectList,
          this.RelatecategorySelectList
        );
      }
    },
  },
  methods: {
    show() {
      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    },
    handleSearch(e) {
      if (e.type === "keyup") {
        if (e.keyCode === 13) {
          this.filter.page = 1;
          Vue.nextTick(() => {
            this.$emit("setUpSearch", this.filter, this.name);
          });
        }
      } else if (e.type === "click") {
        this.filter.page = 1;
        Vue.nextTick(() => {
          this.$emit("setUpSearch", this.filter, this.name);
        });
      }
    },
    pagination() {
      Vue.nextTick(() => {
        this.$emit("pagination", this.filter.page, this.name);
      });
    },
    setshowOnly() {
      this.filter.page = 1;
      Vue.nextTick(() => {
        this.$emit(
          "setShowonly",
          this.showOnly,
          this.name,
          this.CategorySelectList,
          this.RelatecategorySelectList
        );
      });
    },
    clearListcategory() {
      this.CategorySelectList = [];
      Vue.nextTick(() => {
        this.$emit("clearListcategory", this.newcategoryidList);
      });
    },
    clearNamecategory() {
      this.newnameselect = "";
      this.RelatecategorySelectList = 0;
      Vue.nextTick(() => {
        this.$emit("clearNamecategory");
      });
    },
    setcategoryList(id, name, nameselect) {
      if (name == "category") {
        Vue.nextTick(() => {
          this.$emit(
            "setCategoryList",
            this.CategorySelectList,
            name,
            id,
            true
          );
        });
      } else {
        this.newnameselect = nameselect;
        Vue.nextTick(() => {
          this.$emit("setCategoryList", id, name);
        });
      }
    },
  },
};
</script>

<style scoped>
.image {
  width: 50%;
  padding-top: 42.9%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.div-input {
  margin-bottom: 15px;
  position: relative;
  white-space: nowrap;
}
.input-custom {
  padding: 0px;
}
.input-custom > label {
  color: #212529;
  font-size: 16px;
  margin-bottom: 2px;
  font-weight: bold;
}
.input-custom > input {
  color: #212529;
  border: 1px solid #d8dbe0;
  border-radius: 0.25rem;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
}
.input-custom > input[size="lg"] {
  height: 45px;
}
.input-custom > input:focus {
  border: 1px solid #d8dbe0;
}
.input-custom.error > input {
  border-color: red !important;
}
::v-deep .input-group-text {
  background-color: #fff;
  padding: 0 0.75rem;
}
input#header-search-bar::placeholder {
  text-transform: capitalize;
}
label[size="lg"].btn-main,
input[size="lg"].custom-input {
  height: 45px;
}

</style>
